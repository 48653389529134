<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="perfis"
        sort-by="nome"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Perfis registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon v-if="canList" medium @click="getPerfis" title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  >Novo Perfil</v-btn
                >
              </template>
              <cadastro-perfil
                :perfil="perfil"
                :formTitle="formTitle"
                @close="close"
                :perfilIndex="perfilIndex"
              />
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title class="v-card mb-4">
                  <span class="headline">Deletar perfil</span>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black darken-1" text @click="closeDeleteItem"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="darken-1"
                    style="background: #f00; color: white"
                    @click="deletarPerfil"
                    >Deletar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canUpdate" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="canDelete" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-btn v-if="canList" color="primary" @click="getPerfis">Reset</v-btn>
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
        
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CadastroPerfil from './CadastroPerfil.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  components: { CadastroPerfil },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nome', value: 'nome' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      perfilIndex: -1,
      perfil: {
        nome: '',
      },
    }
  },
  computed: {
    ...mapState('gerencial', ['perfis']),

    permission() {
      return Sequences.SistemaPerfis.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    
    formTitle() {
      return this.perfilIndex === -1 ? 'Adicionar novo perfil' : 'Editar perfil'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.getPerfis()
    }
  },

  methods: {
    ...mapActions('gerencial', [
      'getPerfis',
      'addPerfil',
      'editPerfil',
      'deletePerfil',
    ]),

    editItem(item) {
      this.$emit('editar', item)
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.perfilIdDelete = item.id
    },

    closeDeleteItem() {
      this.dialogDelete = false
      this.perfilIdDelete = -1
    },

    deletarPerfil() {
      if (this.perfilIdDelete > -1) {
        this.deletePerfil(this.perfilIdDelete)
      }
      this.perfilIdDelete = -1
      this.dialogDelete = false
    },

    close() {
      this.perfilIndex = -1
      this.dialog = false
    },
  },
}
</script>
