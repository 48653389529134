<template>
  <v-card class="mb-7">
    <validation-observer ref="observer">
      <v-form>
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <validation-provider
              v-slot="{ errors }"
              name="Nome"
              rules="required"
            >
              <v-text-field
                label="Nome"
                :error-messages="errors"
                v-model="perfil.nome"
                required
              ></v-text-field>
            </validation-provider>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <buttons-cadastro @close="reset" @save="atualizarNome" />
          </v-card-actions>
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import PerfilApi from '@/services/gerencial/perfil'
import { mapActions } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  mixins: [validationMixin],

  components: { ButtonsCadastro },

  props: {
    value: {
      type: Object,
      required: true,
    },
    perfilNome: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      perfil: this.value,
    }
  },

  watch: {
    value(val) {
      Object.assign(this.grupo, val)
      this.$forceUpdate()
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async atualizarNome() {
      try {
        await PerfilApi.setNome(this.perfil.public_id, this.perfil.nome)
        this.successMessage('Nome atualizado com sucesso')
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    reset() {
      this.$emit('cancelarEdicao')
    },
  },
}
</script>

<style>
</style>
