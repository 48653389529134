<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Perfis">
          <editar-perfil
            v-if="editarPerfil"
            @cancelarEdicao="cancelarEdicao"
            :perfil="perfilSelecionado"
          />
          <listar-perfis v-else @editar="editar" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarPerfis from '@/components/sistema/perfis/ListarPerfis'
import EditarPerfil from '@/components/sistema/perfis/editarPerfil/FormEditarPerfil'
export default {
  data() {
    return {
      page: {
        title: 'Gerenciamento de perfis',
      },
      breadcrumbs: [
        {
          text: 'Sistema',
          disabled: true,
        },
        {
          text: 'Perfis',
          disabled: false,
        },
      ],
      perfilSelecionado: {},
      editarPerfil: false,
    }
  },
  components: {
    ListarPerfis,
    EditarPerfil,
  },
  methods: {
    editar(perfil) {
      this.perfilSelecionado = perfil
      this.editarPerfil = true
    },
    cancelarEdicao() {
      this.perfilSelecionado = {}
      this.editarPerfil = false
    },
  },
}
</script>

<style></style>
