<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="perfil.nome"
            :rules="[
              () => !!perfil.nome || 'Esse campo é obrigatório!',
            ]"
            ref="nome"
            label="Nome"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" />
    </template>
  </base-modal>
</template>
<script>
import { mapActions } from 'vuex'
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'CadastroCor',
  props: {
    perfil: Object,
    formTitle: String,
    perfilIndex: Number,
  },

  data() {
    return {
      formError: false,
      defaultItem: {
        nome: '',
      },
    }
  },

  computed: {
    form() {
      return {
        nome: this.perfil.nome,
      }
    },
  },

  methods: {
    ...mapActions('gerencial', [
      'addPerfil',
      'editPerfil',
      'deletePerfil',
    ]),

    close() {
      this.formError = false
      setTimeout(() => {
        Object.assign(this.perfil, this.defaultItem)
        this.resetarFormulario()
      }, 300)

      this.$emit('close')
    },

    resetarFormulario() {
      this.formError = false

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },

    verificaFormulario() {
      this.formError = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formError = true

        this.$refs[f].validate(!this.form[f])
      })
    },

    save() {
      this.verificaFormulario()
      if (this.formError) return

      if (this.perfilIndex > -1) {
        this.editPerfil(this.perfil)
      } else {
        this.addPerfil(this.perfil)
      }

      this.close()
    },
  },
}
</script>
<style>
</style>