<template>
  <div class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados"> Dados gerais <v-icon>mdi-home-city</v-icon></v-tab>
      <v-tab href="#menus"> Acessos <v-icon>mdi-sitemap</v-icon></v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-perfil v-model="perfil" :perfilNome="perfil.nome" @cancelarEdicao="$emit('cancelarEdicao')"/>
        </v-tab-item>
        <v-tab-item value="menus">
          <items-menu
            @cancelarEdicao="$emit('cancelarEdicao')"
            :itemsSelected="perfil.menu"
            :perfil="perfil.public_id"
            :basicPermissions="perfil.basic_permissions"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosPerfil from './DadosPerfil.vue'
import ItemsMenu from './ItemsMenu.vue'

export default {
  props: {
    perfil: {
      type: Object,
      required: true,
    },
  },
  components: { DadosPerfil, ItemsMenu },
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    choosedIds() {
      return this.perfil.permissions.map(({ id }) => id)
    }
  },
  methods: {},
}
</script>

<style>
</style>
