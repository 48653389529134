<template>
  <div>
    <v-dialog
      v-model="showMenuPermissions"
      max-width="30%"
      v-if="showMenuPermissions"
    >
      <items-menu-permission
        :itemMenu="menu"
        @cancelar="showMenuPermissions = false"
        @save="saveItemMenuBasicPermissions"
      />
    </v-dialog>
    <v-list-item-subtitle class="text-wrap">
      Selecione os items que o perfil pode ter acesso.
    </v-list-item-subtitle>
    <v-treeview
      v-model="currentItems"
      selectable
      open-on-click
      transition
      :items="items"
      item-text="title"
      item-key="sequence"
      class="d-flex flex-column align-start text-left"
      @input="handleChange"
    >
      <template #append="{ item }">
        <v-icon
          v-if="!item.children"
          class="ml-4"
          title="Configurar permissões"
          @click="configMenuPermissions(item)"
          >mdi-cog</v-icon
        >
      </template></v-treeview
    >
    <v-card-actions>
      <v-spacer />
      <buttons-cadastro @close="reset" @save="atualizarMenu" />
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import ItemsMenuPermission from './ItemsMenuPermission.vue'
export default {
  components: { ButtonsCadastro, ItemsMenuPermission },
  props: {
    itemsSelected: {
      type: Array,
      required: true,
    },
    perfil: {
      type: String,
      required: true,
    },
    basicPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('menu', ['items']),
  },
  data() {
    return {
      currentItems: [],
      cloneItems: [],
      acessos: [],
      showMenuPermissions: false,
      menu: {
        basicPermissions: [],
      },
      allBasicPermissions: {},
    }
  },
  async created() {
    this.currentItems = this.itemsSelected
    this.cloneItems = this.currentItems
    this.allBasicPermissions = this.basicPermissions
  },
  methods: {
    ...mapActions('gerencial', ['updatePerfil']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    handleChange(items) {
      const cloneLength = this.cloneItems.length
      if (cloneLength < items.length) {
        this.giveAllPermissionsOnSelect(items[cloneLength])
      } else {
        const sequence = this.cloneItems.find((i) => !items.includes(i))
        if (sequence) this.takeAllPermissionsOnUnselect(sequence)
      }
      this.cloneItems = items
    },
    giveAllPermissionsOnSelect(sequence) {
      this.allBasicPermissions[sequence] = [
        'list',
        'create',
        'update',
        'delete',
      ]
    },
    takeAllPermissionsOnUnselect(sequence) {
      this.allBasicPermissions[sequence] = []
    },
    saveItemMenuBasicPermissions(sequence, permissions) {
      this.allBasicPermissions[sequence] = permissions
      this.showMenuPermissions = false
    },
    configMenuPermissions(menu) {
      this.menu = {
        ...menu,
        basicPermissions: this.allBasicPermissions[menu.sequence] || [],
      }
      this.showMenuPermissions = true
    },
    async atualizarMenu() {
      try {
        await this.updatePerfil({
          perfilPublicId: this.perfil,
          payload: {
            menu: this.currentItems,
            basic_permissions: this.allBasicPermissions,
          },
        })
        this.successMessage('Menus definido com sucesso')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    reset() {
      this.$emit('cancelarEdicao')
    },
  },
}
</script>

<style></style>
