<template>
  <v-card class="pa-4">
    <v-card-subtitle class="text-center pt-2">
      Permissões para a tela de <strong>{{ item.title }}</strong>
    </v-card-subtitle>
    <v-card-text class="px-8">
      <v-simple-table dense class="text-left text-uppercase">
        <template v-slot:default>
          <tr>
            <th>Visualizar</th>
            <td class="d-flex justify-center">
              <v-checkbox
                v-model="item.basicPermissions"
                :value="BasicPermissions.VISUALIZAR"
              />
            </td>
          </tr>
          <tr>
            <th>Adicionar</th>
            <td class="d-flex justify-center">
              <v-checkbox
                v-model="item.basicPermissions"
                :value="BasicPermissions.CADASTRAR"
              />
            </td>
          </tr>
          <tr>
            <th>Alterar</th>
            <td class="d-flex justify-center">
              <v-checkbox
                v-model="item.basicPermissions"
                :value="BasicPermissions.EDITAR"
              />
            </td>
          </tr>
          <tr>
            <th>Excluir</th>
            <td class="d-flex justify-center">
              <v-checkbox
                v-model="item.basicPermissions"
                :value="BasicPermissions.EXCLUIR"
              />
            </td>
          </tr>
          <tr v-for="extra in item.extra_perms" :key="extra.value">
            <th>
              {{ extra.title }}
            </th>
            <td class="d-flex justify-center">
              <v-checkbox
                v-model="item.basicPermissions"
                :value="extra.value"
              />
            </td>
          </tr>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn plain @click="$emit('cancelar')">Cancelar</v-btn>
      <v-btn
        color="secondary"
        @click="$emit('save', item.sequence, item.basicPermissions)"
        >Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { BasicPermissions } from '@/utils/permissions'

export default {
  name: 'ItemsMenuPermission',
  props: {
    itemMenu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      BasicPermissions,
      item: { ...this.itemMenu },
    }
  },
}
</script>
